<template>
  <b-overlay
    :variant="'transparent'"
    :opacity="1.00"
    :blur="'1px'"
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>{{ $t("Statistic") }}</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in data"
            :key="item.icon"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 ">
                  <!-- {{ item.title }} -->
                  <ICountUp
                    :delay="delay"
                    :end-val="item.title"
                    :options="options"
                  />
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import axios from '@axios'
import { isUserInGroup, messageError, utf8ToB64 } from '@/store/functions'
// eslint-disable-next-line import/no-extraneous-dependencies
import ICountUp from 'vue-countup-v2'

import { getUserData } from '@/auth/utils'
import * as constants from '@core/utils/constants'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    ICountUp,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BOverlay,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      show: false,
      data: [
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: 0,
          subtitle: this.$t('finalUsers.title'),
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'FolderIcon',
          color: 'light-primary',
          title: 0,
          subtitle: this.$t('contents.categories'),
          customClass: 'mb-2 mb-xl-0',
        },

        {
          icon: 'TvIcon',
          color: 'light-success',
          title: 0,
          subtitle: this.$t('Contents'),
          customClass: '',
        },
        {
          icon: 'EyeIcon',
          color: 'light-success',
          title: 0,
          subtitle: this.$t('publishContents'),
          customClass: '',
        },
        {
          icon: 'PenToolIcon',
          color: 'light-danger',
          title: 0,
          subtitle: this.$t('Media'),
          customClass: 'mb-2 mb-sm-0',
        },
      ],
      isSponsor: false,
      userData: getUserData(),
      delay: 1000,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
    }
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    if (!this.isSponsor) { this.getTotals() } else { this.getTotalSponsor() }
  },
  methods: {

    getTotals() {
      this.show = true
      axios.post('', {
        query: `
        query {
          allContents(client:"${this.userData.profile.client.id}"){
            totalCount
          }
          active: allContents(state:GRE,isActive:true,client:"${this.userData.profile.client.id}"){
            totalCount
          }
          allCategories(client:"${this.userData.profile.client.id}") {
            totalCount
          }
          ${this.userData.profile.client.isShop ? `allProducts(client:"${this.userData.profile.client.id}"){
            totalCount
                }` : ''}
          allFiles(client:"${this.userData.profile.client.id}"){
            totalCount
          }
          allVusers(client:"${this.userData.profile.client.id}") {
            totalCount
          }  
        }
      `,
      })
        .then(res => {
          messageError(res, this)

          const dataRes = res.data.data
          this.data = [

            {
              icon: 'UserIcon',
              color: 'light-info',
              title: dataRes.allVusers.totalCount,
              subtitle: this.$t('finalUsers.title'),
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'FolderIcon',
              color: 'light-primary',
              title: dataRes.allCategories.totalCount,
              subtitle: this.$t('contents.categories'),
              customClass: 'mb-2 mb-xl-0',
            },

            {
              icon: 'TvIcon',
              color: 'light-success',
              title: dataRes.allContents.totalCount,
              subtitle: this.$t('Contents'),
              customClass: '',
            },
            {
              icon: 'EyeIcon',
              color: 'light-success',
              title: dataRes.active.totalCount,
              subtitle: this.$t('publishContents'),
              customClass: '',
            },
            {
              icon: 'PenToolIcon',
              color: 'light-danger',
              title: dataRes.allFiles.totalCount,
              subtitle: this.$t('Media'),
              customClass: 'mb-2 mb-sm-0',
            },
          ]
          this.show = false
        })
        .catch(error =>
          // eslint-disable-next-line
          console.log(error))
    },
    getTotalSponsor() {
      axios.post('', {
        query: `
        query {
          allContents(client:"${this.userData.profile.client.id}",
          ${this.userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${this.userData.id}`)}"`}
          ){
            totalCount
          }
          active: allContents(state:GRE,isActive:true, client:"${this.userData.profile.client.id}",
          ${this.userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${this.userData.id}`)}"`}){
            totalCount
          }
          allCategories(client:"${this.userData.profile.client.id}",
          filter_Sponsor:"${this.userData.id}") {
            totalCount
          }         
          allFiles(client:"${this.userData.profile.client.id}",
          ${this.userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${this.userData.id}`)}"`}){
            totalCount
          }   
          allAds(client:"${this.userData.profile.client.id}",
          ${this.userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${this.userData.id}`)}"`}){
            totalCount
          }      
          allCampaigns(client:"${this.userData.profile.client.id}",
          ${this.userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${this.userData.id}`)}"`}){
            totalCount
          }             
        }
      `,
      })
        .then(res => {
          messageError(res, this)

          const dataRes = res.data.data
          this.data = [

            {
              icon: 'FolderIcon',
              color: 'light-primary',
              title: dataRes.allCategories.totalCount,
              subtitle: this.$t('contents.categories'),
              customClass: 'mb-2 mb-xl-0',
            },

            {
              icon: 'TvIcon',
              color: 'light-success',
              title: dataRes.allContents.totalCount,
              subtitle: this.$t('Contents'),
              customClass: '',
            },
            {
              icon: 'EyeIcon',
              color: 'light-success',
              title: dataRes.active.totalCount,
              subtitle: this.$t('publishContents'),
              customClass: '',
            },
            {
              icon: 'PenToolIcon',
              color: 'light-danger',
              title: dataRes.allFiles.totalCount,
              subtitle: this.$t('Media'),
              customClass: 'mb-2 mb-sm-0',
            },
            {
              icon: 'BarChart2Icon',
              color: 'light-info',
              title: dataRes.allAds.totalCount,
              subtitle: this.$t('settingTabs.ads'),
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'BriefcaseIcon',
              color: 'light-info',
              title: dataRes.allCampaigns.totalCount,
              subtitle: this.$t('advertising.Campaigns'),
              customClass: 'mb-2 mb-xl-0',
            },
          ]
        })
        .catch(error =>
          // eslint-disable-next-line
          console.log(error))
    },
  },
}
</script>
